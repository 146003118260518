<template>
    <div class="container flex flex-col">
        <div class="bg-white-pure list-wrapper">
            <div class="">
                <h1 class="text-black mr-2 text-xl lg:text-2xl" data-cy="name">Network Error</h1>
                <div class="mt-4">
                    <div>It seems that there is a problem in the network.</div>
                    <div v-if="message">(Detail: {{ message }})</div>
                    <div class="mt-1">Please try again by clicking <a :href="redirectUri">here</a>.</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NetworkError',
    data() {
        return {
            message: this.$route.params?.message,
            redirectPath: this.$route.params?.redirectPath
        }
    },
    methods: {
        refresh() {
            this.$router.push(this.redirectPath ?? '/')
        }
    },
    computed: {
        redirectUri() {
            return this.redirectPath?.path ?? '/'
        },
        loggedIn() {
            return this.$auth.isLoggedIn()
        }
    },
    watch: {
        loggedIn() {
            this.loggedIn ?? this.refresh()
        }
    }
}
</script>
